import axios from '@/libs/axios'

export default class Suppliers {

    /**
     * Function to branches
     * @return Promise
     */
    index(query) {
        return axios.get(`/Proveedores/ObtenerProveedores`, { params: query });
    }

    all() {
        return axios.get(`/Proveedores/GetAll`);
    }

    findById (id) {
        return axios.get(`/Proveedores/ObtenerProveedoresPorId/${id}`);
    }

    getTypeSuppliers () {
        return axios.get(`/Proveedores/GetTipoProveedores`);
    }
}
