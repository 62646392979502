import axios from '@/libs/axios'

export default class JmrsRotacionesArts {
    
    /**
     * Obtener ultima fecha de actualizacion de la tabla de jmrs_rotaciones_arts
     * @return Promise
     */
    index() {
        return axios.get(`/JmrsRotacionesArts/GetJmrsRotacionesArts`)
    }

    findByAlmacenId(AlmacenId) {
        return axios.get(`/JmrsRotacionesArts/GetJmrsRotacionesArtsByAlmaceId/${AlmacenId}`)
    }

    listPurchases(AlmacenId, RolClaveArticuloId){
        return axios.get(`/JmrsRotacionesArts/GetJmrsRotacionesConExistencias/${AlmacenId}/${RolClaveArticuloId}`)
    }

    exportPurchases(AlmacenId, rolClavePrincipalId) {
        return axios.get(`/JmrsRotacionesArts/exportarExcel`, {
            params: {
                codigoAlmacen: AlmacenId,
                rolClavePrincipalId: rolClavePrincipalId,
            },
            responseType: 'blob' // Importante para recibir datos binarios (blob)
        })        
    }
}